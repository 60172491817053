/* scrolling */
.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

/* position */
.w-full {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* style */
.cursor-pointer {
  cursor: pointer;
}

.font-bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.text-white {
  color: #fff;
}

.bg-black {
  background-color: #000;
}

.text-hp-red {
  color: #a6192e;
}

.text-blue-500 {
  color: #3b82f6;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.whitespace-normal	{
  white-space: normal;
}

/* font-sizes */

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

.text-8xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}

.text-9xl {
  font-size: 8rem; /* 128px */
  line-height: 1;
}

/* display */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.items-center {
  align-items: center;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-baseline {
  align-items: baseline;
}

.content-stretch {
  align-content: stretch;
}

/* tangible */


.MuiChip-root {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.nav-blend {
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, #fff, #f2f2f2);
}