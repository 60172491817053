@import 'tailish.scss';

html {
  width: 100%; 
  height: 100%;
  background-color: #eee;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%; 
  height: 100%;
}

div#root {
  width: 100%; 
  height: 100%;
  margin: 0;
}

#root > div {
  height: 100%;
  //width: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-height-with-toolbar {
  height: calc(100vh - 64px);
}

@media (max-width: 959px) and (min-width: 0) and (orientation: portrait) {
  .full-height-with-toolbar {
    height: calc(100vh - 64px);
  }
}

@media (max-width: 959px) and (min-width: 0) and (orientation: landscape) {
  .full-height-with-toolbar {
    height: calc(100vh - 64px);
  }
}

.athena-facet-option-count {
  color: rgba(0, 0, 0, 0.5);
  margin-left: auto;
  line-height: 11%;
  cursor: default
}


.athena-clear-facet {
  width: 30px;
  height: 39px;
  padding-top: 6px;
  margin-right: 10px;
  background-color: #a6192e;
  text-align: center;
  color: white;
  /* float: left;
  position: relative;
  z-index: 1000; */
  cursor: pointer;
  /* &:hover{
    background-color: #8b1527 !important;
  } */
}

/* app bar */
header .MuiToolbar-gutters {
  padding: 0 8px;
}
/* start facetSideNav */
#facetSideNav .MuiListItem-gutters {
  padding-left: 6px;
  padding-right: 6px;
}

/* grid */
.e-headertext {
  font-weight: bold;
}

/* util */

.break {
  flex-basis: 100%;
  height: 0;
}

//.switcher-with-two-choices {
//  border: 0 solid #900;
//}

// .flex {
//   -webkit-box-flex: 1;
//   -webkit-flex: 1;
//   flex: 1;
//   box-sizing: border-box;
// }

.layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.hide-on-print {
  visibility: hidden;
  display: none;
  background-color: white;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.lifted {
  position: relative;
  z-index: 20;
  padding: 20px;
}

h3.sectionHeading {
  font-family: 'RobotoDraft','Roboto','Helvetica Neue, Helvetica, Arial',sans-serif;
  color: #a6192e;
  width: 100%;
  font-weight: 400;
  text-align: left;
}

//.switch-approval:before {
//  content: 'Approval: ';
//  display: 'inline-block';
//}

.accordionDetailEnd {
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #999, #aaa);
}

// for infocards
#detail-container > .MuiCardHeader-content {
  height: 58px;
  display: flex;
  align-items: center;
}

.info-card .MuiCardHeader-content {
  display: block;
}
.info-card-header {
  display: block;
}

.innerDialogPadding {
  padding: 2px 25px 20px 20px;
}

.hovertip {
  color: #999;
}

.infotip-icon {
  color: #777;
  width: 20px;
  height: 20px;
}

button span .infotip-icon {
  color: #777;
  width: 20px;
  height: 20px;
}

h2.infotip-modal {
  margin-top: 2px;
}

// color ref
// fb blue - #3B5998
//
//.e-dlg-content .e-date-wrapper label {
//  color: white !important;
//  font-size: 1px;
//  //display: none !important;
//
//}
//.e-dlg-content .e-date-wrapper label:after {
//  //color: white !important;
//  color: rgba(0,0,0,0.87) !important;
//  font-size: 13px;
//  content: 'Date';
//}

