@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@syncfusion/ej2-notifications/styles/material.css';
@import '@syncfusion/ej2-react-grids/styles/material.css';
@import '@syncfusion/ej2-icons/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-richtexteditor/styles/material.css';

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
}

#appContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

#appBarBlock {
  display: block;
}

#mainContentBlock {
  /*display: block;*/
  flex: 1;
  /*height: calc(100vh - 64px);*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

#mainContent {
  width: 100%;
  height: calc(100% - 64px);
  margin: 0;
  /*background-color: #c6c8cc;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* fix for MuiAccordion - note in version 5 I think you can disableGutters */
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important
}